import {BrowserRouter as Router, Routes, Route, useNavigate} from "react-router-dom";
import Error from "./error";
import {Layout, Menu, Watermark, Input, Typography, Row, Col, Divider, Space} from "antd";
import Home from "./Home.tsx";
import "./App.css";
import Aegis from "aegis-web-sdk";
import {RecoilRoot} from "recoil";
import {useEffect} from "react";
import {checkExpire} from "login.ts";
const {Content, Footer} = Layout;

function FooterContent() {
  return (
    <>
      <Divider />
      <center>
        <a target="_blank" href="https://beian.miit.gov.cn/">
          <img src={require("./images/beian.png")} style={{marginRight: 5, width: 20}} />
          沪ICP备2023039698号-1
        </a>
        <Divider type="vertical" />
        Powered by Ar-Sr-Na
      </center>
    </>
  );
}

function Main() {
  const navigate = useNavigate();
  useEffect(() => {
    checkExpire();
  }, []);
  return (
    <>
      <Watermark content="Ar-Sr-Na 测试版本">
        <Layout style={{minHeight: "100vh"}}>
          <div className="nav-blur" style={{height: 60}}>
            <img height="45" src="https://res.arsrna.cn/logo.png" style={{paddingRight: 5}} alt="logo" />
            <span className="lead">个人中心 | Ar-Sr-Na</span>
            <Menu
              mode="horizontal"
              defaultSelectedKeys={["/"]}
              style={{marginLeft: 30, background: "rgba(0,0,0,0)"}}
              items={[
                {
                  label: "首页",
                  key: "/",
                },
              ]}
              onClick={e => navigate(e.key)}></Menu>
          </div>
          <Content style={{padding: "80px 20px 0px 20px"}}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="*" element={<Error />} />
            </Routes>
          </Content>
          <Footer>
            <FooterContent />
          </Footer>
        </Layout>
      </Watermark>
    </>
  );
}

function App() {
  const aegis = new Aegis({
    id: "QlWyV8Np04vdN15Yok", // 上报 id
    uin: "xxx", // 用户唯一 ID（可选）
    reportApiSpeed: true, // 接口测速
    reportAssetSpeed: true, // 静态资源测速
    spa: true, // spa 应用页面跳转的时候开启 pv 计算
    hostUrl: "https://rumt-zh.com",
  });
  return (
    <RecoilRoot>
      <Router>
        <Main />
      </Router>
    </RecoilRoot>
  );
}

export default App;
