import {useEffect, useState} from "react";
import {ArCIAM, handleLogin, handleLogout, parseIdToken} from "./login";
import {
  Button,
  Card,
  Form,
  Input,
  message,
  Avatar,
  Flex,
  Row,
  Col,
  Modal,
  Popconfirm,
  Space,
  Steps,
  Tooltip,
  Table,
} from "antd";
import APIEP from "./api";
import md5 from "md5";
import Error from "./error";
import {useRecoilState} from "recoil";
import {isLoginState} from "state";
import axios from "axios";
import moment from "moment";

export default function Home() {
  const [isLogin, setIsLogin] = useRecoilState(isLoginState);
  const [info, setInfo] = useState(null);
  const [nickname, setNickname] = useState(null);

  /**
   * @description 检测登录后逻辑
   */
  function detectLoginCode() {
    const query = new URLSearchParams(location.search);
    let code = query.get("code");
    if (code === null) return;
    //code存在，说明需要登录
    ArCIAM.handleGetToken(code);
  }

  useEffect(() => {
    //检测登录情况
    const islogin = localStorage.CIAM !== void "我永远喜欢爱莉希雅";
    setIsLogin(islogin);

    if (islogin) {
      let {payload} = parseIdToken();
      setInfo(payload);
      //   console.log(parseIdToken());
      setNickname(payload.nickname);
    }

    //处理登录后逻辑
    detectLoginCode();
  }, []);

  //修改邮箱弹层
  // const [emailChange, setEmailChange] = useState(false);

  return (
    <>
      {info === null && (
        <Error
          title="请先登录"
          desc={
            <center>
              本页内容仅登录用户可见。
              <br />
              <Button type="primary" onClick={handleLogin}>
                点此登录
              </Button>
            </center>
          }
        />
      )}

      {/* 有info说明已经登录获取到基本信息了，所以不用慌 */}
      {info !== null && (
        <Flex vertical gap="large">
          <Card
            title="基础信息"
            extra={
              <a onClick={handleLogout} type="primary">
                退出登录
              </a>
            }>
            {/* <ChangeEmail show={emailChange} setShow={setEmailChange} /> */}

            <Row gutter={16} style={{alignItems: "center"}}>
              <Col md={12}>
                <Space>
                  <Avatar
                    onClick={() => window.open("https://cravatar.cn/avatars", "_blank")}
                    size={80}
                    src={<img src={`https://cravatar.cn/avatar/${md5(info.email)}?s=400`} alt="avatar" />}
                  />
                  <div>
                    <small style={{color: "gray"}}>点击头像以更换</small>
                    <br />
                    <Flex gap="small" vertical>
                      <Space.Compact>
                        <Input addonBefore="昵称" value={nickname} onChange={e => setNickname(e.target.value)} />
                        <Popconfirm
                          title="确定更改吗？"
                          onConfirm={() => {
                            changeUserInfo("userinfo", "PATCH", JSON.stringify({nickname})).then(msg => {
                              // console.log(msg)
                              message.success("更改成功，下次登录生效");
                            });
                          }}>
                          <Button type="primary" disabled={nickname === info.nickname}>
                            修改
                          </Button>
                        </Popconfirm>
                      </Space.Compact>

                      <Tooltip
                        title={
                          <>
                            禁止用户自行修改此项，如需修改，请
                            <a href="https://www.arsrna.com/contact" target="_blank">
                              联系我们
                            </a>
                            。
                          </>
                        }>
                        <Input addonBefore="邮箱" value={info.email} readOnly />
                      </Tooltip>
                      {/* <Button type="primary" onClick={() => setEmailChange(true)}>修改</Button> */}
                    </Flex>
                  </div>
                </Space>
              </Col>
              <Col md={12}>
                <small style={{color: "gray"}}>点击文本框复制</small>
                <br />
                <Flex gap="small" vertical>
                  <Input
                    style={{width: "100%"}}
                    addonBefore="唯一用户UID"
                    value={info.sub}
                    readOnly
                    onClick={e => {
                      navigator.clipboard
                        .writeText(e.currentTarget.value)
                        .then(msg => message.success("已复制"))
                        .catch(err => message.error("复制失败：" + err));
                    }}
                  />
                  <Input
                    style={{width: "100%"}}
                    addonBefore="用户组GID"
                    value={info.jti}
                    readOnly
                    onClick={e => {
                      navigator.clipboard
                        .writeText(e.currentTarget.value)
                        .then(msg => message.success("已复制"))
                        .catch(err => message.error("复制失败：" + err));
                    }}
                  />
                </Flex>
              </Col>
            </Row>
          </Card>
          <AIBalance />
          <BalanceHistory />
        </Flex>
      )}
    </>
  );
}

function AIBalance() {
  // console.log(token);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (localStorage.CIAM === void "我永远喜欢甘雨") return;
    const token = JSON.parse(localStorage.CIAM).access_token;
    axios
      .get(APIEP.balance, {
        headers: {token},
      })
      .then(({data}) => {
        console.log(data);
        if (data.success) {
          setDataSource(data.data);
          return;
        }
        message.error("获取额度失败！请检查传参");
      })
      .catch(err => {
        console.warn(err);
        message.error("获取额度失败！请检查传参");
      });
  }, []);

  return (
    <>
      <Card title="AI中心调用额度">
        <Table
          dataSource={dataSource}
          columns={[
            {
              title: "产品id",
              dataIndex: "productId",
              key: "productId",
            },
            {
              title: "产品名",
              dataIndex: "productName",
              key: "productName",
            },
            {
              title: "额度",
              dataIndex: "balance",
              key: "balance",
            },
          ]}
          rowKey="productId"
        />
      </Card>
    </>
  );
}

function ChangeEmail({show, setShow}) {
  const [lock, setLock] = useState(false);
  const [lockTime, setLockTime] = useState(60);
  const token = localStorage.token;

  /**
   *
   * @description 发送OTP验证码
   * @param {string} type OTP操作类型
   * @param {string} email 原始邮箱
   */
  const OTPSend = (type, email) => {
    setLock(true);
    //冷却逻辑
    const sendTime = new Date().getTime();
    const count = setInterval(() => {
      const nowTime = new Date().getTime();
      if (sendTime + 60 * 1000 >= nowTime) {
        setLockTime(60 - Math.floor((nowTime - sendTime) / 1000));
      } else {
        setLock(false);
        setLockTime(60);
        clearInterval(count);
      }
    }, 999);
    fetch("https://arsrna.portal.tencentciam.com/otp/send", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Basic TVRZeE1UZG1abUV6TldabU5EQTFOVGs1TkdWaFpXTXlNREk1T0RJeE9UUTpNZGNrWUVsRlk4YlNMWnVYb2FzRVZ2ZEU5UHVhWkdQdg==",
      },
      body: JSON.stringify({type, email, auth_source_id: "b9e8f5b1-a558-4f2a-b933-c082151c7c11"}),
    })
      .then(msg => msg.json())
      .then(msg => {
        message.success("验证码已发送，请注意查收！");
        localStorage.setItem("otptoken", msg.otp_token);
      })
      .catch(e => message.error("验证码发送失败：" + e));
  };
  return (
    <Modal title="修改邮箱" open={show} footer={null} onCancel={() => setShow(false)}>
      验证当前邮箱是否有效
      <Form
        onFinish={({email_otp, email}) => {
          changeUserInfo(
            "userinfo",
            "PATCH",
            JSON.stringify({
              email_otp_token: localStorage.otptoken,
              email_otp,
              email,
            })
          ).then(console.log);
        }}>
        <Space.Compact>
          <Form.Item label="验证码" name="email_otp" rules={[{required: true, message: "请输入"}]}>
            <Input />
          </Form.Item>
          <Button
            disabled={lock}
            onClick={() => OTPSend("update_userinfo", parseIdToken().payload.email)}
            type="primary">
            {lock ? `发送验证码(${lockTime}s)` : "发送验证码"}
          </Button>
        </Space.Compact>

        <Form.Item label="更新后邮箱" name="email" rules={[{required: true, message: "请输入"}]}>
          <Input type="email" />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            修改
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

function BalanceHistory() {
  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    if (localStorage.CIAM === void "我永远喜欢甘雨") return;
    const token = JSON.parse(localStorage.CIAM).access_token;
    axios
      .get(APIEP.balanceHistory, {
        headers: {token},
      })
      .then(({data}) => {
        console.log(data);
        if (data.success) {
          setDataSource(data.data);
          return;
        }
        message.error("获取额度历史失败！请检查传参");
      })
      .catch(err => {
        console.warn(err);
        message.error("获取额度历史失败！请检查传参");
      });
  }, []);

  return (
    <>
      <Card title="AI中心调用历史">
        <Table
          dataSource={dataSource}
          columns={[
            {
              title: "产品id",
              dataIndex: "productId",
              key: "productId",
            },
            {
              title: "产品名",
              dataIndex: "productName",
              key: "productName",
            },
            {
              title: "变动额度",
              dataIndex: "quantity",
              key: "quantity",
            },
            {
              title: "时间（GMT+8）",
              dataIndex: "createtime",
              key: "createtime",
              render: value => moment(value).add(-8, "hours").format("YYYY-MM-DD HH:mm:ss"),
            },
          ]}
          rowKey="createtime"
        />
      </Card>
    </>
  );
}

/**
 *
 * @param path arsrna.portal.tencentciam.com 请求路径
 * @param method 请求方式
 * @param body 请求体
 * @returns 请求回复
 */
async function changeUserInfo(path: string, method: RequestInit["method"], body: RequestInit["body"]) {
  const token = localStorage.token;
  return await fetch(`https://arsrna.portal.tencentciam.com/${path}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/json",
    },
    method,
    body,
  }).then(msg => msg.text());
}

// function OTPSend(usage, email) {
//     const sendTime = new Date().getTime();
//     const count = setInterval(() => {
//         const thisDate = new Date().getTime()
//         if (thisDate - sendTime <= 60000) {
//             $('#otpSendBtn').html(`发送验证码 (${60 - parseInt((thisDate - sendTime) / 1000)}s)`)
//             $('#otpSendBtn').addClass('disabled')
//         } else {
//             $('#otpSendBtn').html(`发送验证码`)
//             $('#otpSendBtn').removeClass('disabled')
//             clearInterval(count)
//         }
//         //console.count('发送')
//     }, 999)
//     var ajaxbase = $.ajax({
//         url: 'https://arsrna.portal.tencentciam.com/otp/send',
//         type: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': 'Basic TVRZeE1UZG1abUV6TldabU5EQTFOVGs1TkdWaFpXTXlNREk1T0RJeE9UUTpNZGNrWUVsRlk4YlNMWnVYb2FzRVZ2ZEU5UHVhWkdQdg=='
//         },
//         async: false,
//         data: JSON.stringify({
//             usage: usage,
//             email: email,
//             auth_source_id: 'b9e8f5b1-a558-4f2a-b933-c082151c7c11'
//         }),
//         success(msg) {
//             //console.log(msg)
//             localStorage.setItem('otptoken', msg.otp_token)
//         }
//     });
// }
